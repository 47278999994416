import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#2196F3',
        secondary: '#9DB5B2',
        accent: '#A2C5AC',
        error: '#913325',
        info: '#2196F3',
        success: '#44A048',
        warning: '#FFDB2E'
      },
    },
  },
});

<template>
  <v-app>
    <v-content>
      <v-container fill-height align-center align-content-center>
        <v-row>
          <v-col cols="12" md="4" offset-md="2" order-md="2">
            <v-img eager class="profile-img" src="./assets/andrew.jpeg" />
          </v-col>
          <v-col cols="12" md="6" order-md="1" offset-md="6">
            <h1>Hello,</h1>
          </v-col>
          <v-col cols="12" md="6" order-md="3" class="py-0 bio">
            <h2>My name is Andrew.</h2>
            <p>I am a fullstack developer with a huge passion for technology.</p>
            <p>
              In the free time, I like creating small projects, establishing mini deadlines to practice writing
              clean code in a timely manner and basically just learning new things.
            </p>
            <p>
              I have worked with several languages until now, but I must admit that I am a
              <a>JavaScript</a> hard-core fan.
            </p>
            <hr />
          </v-col>
          <v-col cols="12" order="4" offset-md="2" md="4" class="text-center">
            <v-btn
              color="#9DB5B2"
              icon
              :href="`mailto:andrewmurraydavid@gmail.com`"
              target="_blank"
              rel="noopener"
              x-large
            >
              <v-icon x-large>mdi-at</v-icon>
            </v-btn>
            <v-btn
              color="#9DB5B2"
              icon
              :href="`https://facebook.com/andrewmurraydavid`"
              target="_blank"
              rel="noopener"
              x-large
            >
              <v-icon x-large>mdi-facebook</v-icon>
            </v-btn>
            <v-btn
              color="#9DB5B2"
              icon
              :href="`https://www.instagram.com/andrewmurraydavid`"
              target="_blank"
              rel="noopener"
              x-large
            >
              <v-icon x-large>mdi-instagram</v-icon>
            </v-btn>
            <v-btn
              color="#9DB5B2"
              icon
              :href="`https://www.linkedin.com/in/andrew-david-murray-907867105`"
              target="_blank"
              rel="noopener"
              x-large
            >
              <v-icon x-large>mdi-linkedin</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import "./assets/global.scss";

export default {
  name: "App",

  components: {
  },

  data: () => ({
    //
  })
};
</script>

<style lang="scss" scoped>
.profile-img {
  border-radius: 50%;
  border: 10px solid #9db5b2;
}
.bio {
  a {
    color: #9db5b2 !important;
    font-weight: 600;

    &:visited {
      color: #9db5b2 !important;
      font-weight: 600;
    }
  }
}
</style>